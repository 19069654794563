import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/sitemap.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'
import { useStaticQuery, graphql } from 'gatsby'
import { category } from '../config/category'

export default function SiteMap({ location }: any): JSX.Element {
  const data = useStaticQuery(
    graphql`
      query {
        allArticle(sort: { order: DESC, fields: updatedAt___seconds }) {
          edges {
            node {
              category
              description
              id
              thumbnail
              title
              updatedAt {
                seconds
              }
            }
          }
        }
      }
    `
  )

  const disCategory = category.filter((cate) => cate.name !== 'None')
  const locatePath = location.pathname.split('/')
  const contentsList = data && data.allArticle ? data.allArticle.edges.slice(0) : []
  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'サイトマップ'}
          description={
            'KURORO BLOGで掲載されている記事一覧情報をまとめています。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'smap/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />

        <h1 className="sitemap__h1">サイトマップ</h1>
        <section>
          {disCategory &&
            disCategory.map((cate, cateIdx) => {
              const articleList = contentsList.filter(
                (content: {
                  node: {
                    category: string
                  }
                }) => content.node.category === cate.name
              )
              return (
                <>
                  <h2 key={cateIdx} className="sitemap__h2">
                    {cate.value}について
                  </h2>
                  <ul>
                    {articleList &&
                      articleList.map(
                        (
                          article: {
                            node: {
                              title: string
                              id: string
                            }
                          },
                          articleIdx: number
                        ) => {
                          return (
                            <li key={articleIdx}>
                              <a href={'/' + cate.name + '/' + article.node.id + '/'}>{article.node.title}</a>
                            </li>
                          )
                        }
                      )}
                  </ul>
                </>
              )
            })}
        </section>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/smap/',
              text: 'サイトマップ',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
